import React from "react";
import styles from "./CustomTooltip.module.css";

interface CustomTooltipProps {
    text: string;
    label: string;
    children: React.ReactNode;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ text, label, children }) => {
    let highlightStyle;
    if (label === "Intrinsic Hallucination") {
        highlightStyle = {
            backgroundColor: "#FFC7CE",
            color: "#9C0006",
            fontWeight: "400",
            border: "1px solid #9C0006",
            borderRadius: "15px",
            padding: "5px 10px"
        };
    } else if (label === "Extrinsic Hallucination") {
        highlightStyle = {
            backgroundColor: "#FFEB9C",
            color: "#9C6500",
            fontWeight: "400",
            border: "1px solid #9C6500",
            borderRadius: "15px",
            padding: "5px 10px"
        };
    }

    return (
        <div className={styles.tooltipContainer}>
            {children}
            <div className={styles.tooltip}>
                <div className={styles.tooltipBox}>
                    <p className={styles.statement}>{text}</p>
                    <span style={highlightStyle}>{label}</span>
                </div>
                
            </div>
        </div>
    );
};

export default CustomTooltip;
