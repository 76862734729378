import React, { useState } from "react";

interface ShowMoreLessProps {
    text?: string;
    wordLimit?: number;
}

const ShowMoreLess: React.FC<ShowMoreLessProps> = ({ text = "", wordLimit = 20 }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const getDisplayText = () => {
        const words = text?.split(" ") ?? [];
        if (words.length > wordLimit) {
            if (isExpanded) {
                return text;
            } else {
                return words.slice(0, wordLimit).join(" ") + "...";
            }
        }
        return text;
    };

    return (
        <span>
            <span>{getDisplayText()}</span>
            {text && text.split(" ").length > wordLimit && (
                <span style={{ color: "#5151ff", cursor: "pointer" }} onClick={handleToggle}>
                    {isExpanded ? " Show Less" : " Show More"}
                </span>
            )}
        </span>
    );
};

export default ShowMoreLess;
